$base-thin-font-family: HelveticaNeueThin, Arial, Sans-Serif !default;
$base-light-font-family: CliniquesHelveticaNeueLight, Arial, Sans-Serif !default;
$base-font-family: CliniquesHelveticaNeueLight, Arial, Sans-Serif !default;
$base-font-family-regular: CliniquesHelveticaNeueRegular, Arial, Sans-Serif !default;
$base-font-family-roman: CliniquesHelveticaNeueRegular, Arial, Sans-Serif !default;
$base-italic-font-family: CliniquesHelveticaNeueItalic, Arial, Sans-Serif !default;
$base-black-font-family: CliniquesHelveticaNeueRegular, Arial, Sans-Serif !default;
$base-bold-font-family: CliniquesHelveticaNeueRegular, Arial, Sans-Serif !default;
$base-bolder-font-family: CliniquesHelveticaNeueBold, Arial, Sans-Serif !default;
$base-link-font-family: $base-font-family !default;
$base-button-font-family: $base-bold-font-family !default;
$co_updates: true;
$appt_booking: true;
$cr19: true; // 2019 Redesign
$loyalty: true;
$new-loyalty-popup: true;

// password strengthen
$password_strength: true !default;

// Virtual Appointment Enabling variable
$virtual-appointment: true !default;

// SCSS variable configuration to include styles for adpl.
$adpl_emea_forms_styles: true;
$adpl_tr_forms_styles: true;
$link-font-size: 1.25em;
